<template>
  <base-section id="auth" space="0">
    <v-container>
      <v-col xs="10" sm="8" lg="6" class="mx-auto">
        <router-view />
      </v-col>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "Auth",
  provide: {
    heading: { align: "center" },
  },
};
</script>
<style lang="sass" scoped>
::v-deep .no-active
  background-color: transparent !important
  opacity: 1 !important
  border: 0.17rem solid var(--v-primary-base)
  > span
    color: var(--v-primary-base)

#auth
  min-height: calc( 100vh - 160px )
  display: flex
  justify-content: center
  align-items: center
</style>
